export const allCards = [
  "Ladica, the Stoneclaw",
  "Cynthia, the Queen's Blade",
  "Primal Giant",
  "Setus, the Beastblade",
  "Send 'Em Packing",
  "Blossom Spirit",
  "Avatar of Fruition",
  "Divine Smithing",
  "Chesire Cat",
  "Ghastly Treant",
  "Forest Hermit",
  "Marvelously Mad Hatter",
  "Fertile Aether",

  "Bayleon, Sovereign Light",
  "Mistolina, Forest Princess",
  "Tsubaki of the Demon Blade",
  "Leod, the Crescent Blade",
  "King's Might",
  "Troya, Thunder of Hagelberg",
  "Valse, Champion Deadeye",
  "Princess's Strike",
  "Swift Tigress",
  "Lupine Axeman",
  "Dauntless Commander",
  "Tempered Aether",
  "Elegance in Action",

  "Tetra, Sapphire Rebel",
  "Belphomet, Lord of Aiolon",
  "Riley, Hydroshaman",
  "Eleanor, Cosmic Flower",
  "Delta Cannon",
  "Displacer Bot",
  "Mechanized Lifeform",
  "Splendid Conjury",
  "Mechastaff Sorcerer",
  "Prototype Warrior",
  "Magiblade Witch",
  "Presto Chango",
  "Sagacious Core",

  "Valdain, Cursed Shadow",
  "Neptune, Tidemistress",
  "Wildfire Tyrannosaur",
  "Marion, Elegant Dragonewt",
  "Shadow's Corrosion",
  "Bubbleborne Mermaid",
  "Hoarfrost Triceratops",
  "Whirlwind Pteranodon",
  "Dragonewt Needler",
  "Lightning Velociraptor",
  "Doting Dragoneer",
  "Boomfish",
  "Feral Aether",

  "Mono, Garnet Rebel",
  "Kudlak",
  "Aenea, Amethyst Rebel",
  "Doublame, Duke and Dame",
  "Alpha Drive",
  "Nicola, Forbidden Strength",
  "Hellblaze Demon",
  "Forbidden Art",
  "Robozombie",
  "Bone Drone",
  "Berserk Demon",
  "Ghostwriter",
  "Sanguine Core",

  "Limonia, Flawed Saint",
  "Lapis, Glorious Seraph",
  "Father Refinement",
  "Marione, Light of Balance",
  "Augmentation Bestowal",
  "Bunny-Eared Administrator",
  "Robofalcon",
  "Marcotte, Heretical Sister",
  "Ironknuckle Nun",
  "Dark Bishop",
  "Meowskers, Ruff-Tuff Major",
  "Saintly Core",
  "Meowskers Ambush!",

  "Technolord",
  "Viridia Magna",
  "Mechawing Angel",
  "Desert Pathfinder",
  "Maisha, Hero of Purgation",
  "Robogoblin",
  "Colorful Cook",
  "Purgation's Blade",
  "Aldis, Trendsetting Seraph",
  "Mechagun Wielder",
  "Extreme Carrot",

  "Uzuki Shimamura [P.C.S.]",
  "Kyoko Igarashi [P.C.S.]",
  "Miho Kohinata [P.C.S.]",
  "Chika Yokoyama",
  "Momoka Sakurai",
  "Akiha Ikebukuro",
  "Nene Kurihara",
  "Karin Domyoji",

  "Rin Shibuya [Triad Primus]",
  "Nao Kamiya [Over the Rainbow]",
  "Karen Hojo [Song for Life]",
  "Yasuha Okazaki",
  "Yukimi Sajo",
  "Kako Takafuji",
  "Chizuru Matsuo",
  "Seira Mizuki",

  "Mio Honda [Positive Passion]",
  "Aiko Takamori [Handmade Hapiness]",
  "Akane Hino [Positive Passion]",
  "Kaoru Ryuzaki",
  "Suzuho Ueda",
  "Miria Akagi",
  "Miu Yaguchi",
  "Kumiko Matsuyama",

  "Aiko Takamori",
  "Miku Maekawa",
  "Yuzu Kitami",
  "Anastasia",
  "Brand New Beat",
  "Shinobu Kudo",
  "Yumi Aiba",
  "Goddess by the Sunlit Sea",
  "Honoka Ayase",
  "Azuki Momoi",
  "Kana Imai",
  "Otaha Umeki",
  "A Single Vessel",

  "Kyoko Igarashi",
  "Nagi Hisakawa",
  "Rin Shibuya",
  "Mio Honda",
  "Uzuki Shimamura",
  "Anzu Futaba",
  "Karen Hojo",
  "Sparkling Days",
  "Nao Kamiya",
  "Mayu Sakuma",
  "Kirari Moroboshi",
  "Miho Kohinata",
  "Angelic Maid",

  "Mizuki Kawashima",
  "Shiki Ichinose",
  "Syuko Shiomi",
  "Kanade Hayami",
  "Center Street",
  "Hina Araki",
  "Frederica Miyamoto",
  "Precocious Little Devil",
  "Sarina Matsumoto",
  "Rika Jougasaki",
  "Sae Kobayakawa",
  "Tomoe Murakami",
  "Full Bloom Panorama",

  "Akari Tsujino",
  "Yui Ohtsuki",
  "Fumika Sagisawa",
  "Akira Sunazuka",
  "Tsukasa Kiryu",
  "Arisu Tachibana",
  "Yuka Nakano",
  "Unbound Emotion",
  "Tokiko Zaizen",
  "Noriko Shiina",
  "Yukari Mizumoto",
  "Noa Takamine",
  "Mode Estivale",

  "Ranko Kanzaki",
  "Sachiko Koshimizu",
  "Takumi Mukai",
  "Chitose Kurosaki",
  "Whispers of a Dream",
  "Chiyo Shirayuki",
  "Aki Yamato",
  "My Life, My Sounds",
  "Ryo Matsunaga",
  "Mirei Hayasaka",
  "Rina Fujimoto",
  "Syoko Hoshi",
  "Last Daylight",

  "Kaede Takagaki",
  "Shin Sato",
  "Nana Abe",
  "Akane Hino",
  "Classroom Lily",
  "Risa Matoba",
  "Haru Yuuki",
  "Psychic Maiden",
  "Natalia",
  "Shizuku Oikawa",
  "Layla",
  "Sanae Katagiri",
  "Winter Night Prayer",

  "New Generations",
  "New Wave",
  "Master Trainer",
  "Expert Trainer",
  "Trainer",
  "Rookie Trainer",

  "Lymaga, Forest Champion",
  "Amataz, Fairy Blader",
  "Greenbrier Elf",
  "Wildwood Matriarch",
  "Fairy Dragon",
  "Woodland Cleaver",
  "Assault Jaguar",
  "Spiritshine",
  "Greenwood Guardian",
  "Crossbow Sniper",
  "Mallet Monkey",
  "Elven Sentry",
  "Synchronized Slash",

  "Kagemitsu, Matchless Blade",
  "Ralmia, Sonic Racer",
  "Steadfast Samurai",
  "Hero of Antiquity",
  "Courtly Dance",
  "Quickdraw Maven",
  "Twinsword Master",
  "Grand Acquisition",
  "Samurai Outlaw",
  "Adept Thief",
  "Petalwink Paladin",
  "Levin Scholar",
  "Breakneck Draw",

  "Kuon, Founder of Onmyodo",
  "Mysteria, Magic Founder",
  "Curse Crafter",
  "Hulking Giant",
  "Shikigami Summons",
  "Demoncaller",
  "Traditional Sorcerer",
  "Crimson Meteor Storm",
  "Talisman Disciple",
  "Charming Gentlemouse",
  "Passionate Potioneer",
  "Golem's Rampage",
  "Mirror of Truth",

  "Garyu, Surpreme Dragonkin",
  "Filene, Whitefrost Dragonewt",
  "Phoenix Empress",
  "Wyrm God of the Skies",
  "Whitefrost Whisper",
  "Ice Dancing Dragonewt",
  "Jadelong Tactician",
  "Aquascale Stalwart",
  "Swordwhip Dragoon",
  "Dragonblader",
  "Trident Merman",
  "Dragon Chef",
  "Flamewinged Might",

  "Ginsetsu, Great Fox",
  "Aragavy the Berserker",
  "Shuten-Doji",
  "Bear Pelt Warrior",
  "Yuzuki, Righteous Demon",
  "Kasha",
  "Cougar Pelt Warrior",
  "Unleash the Nightmare",
  "Zashiki-Warashi",
  "Antelope Pelt Warrior",
  "Rookie Succubus",
  "Demonic Procession",
  "Berserker's Pelt",

  "Wilbert, Grand Knight",
  "Karula, Arts Master",
  "Saintly Leader",
  "Phantom Blade Wielder",
  "Manifest Devotion",
  "Holy Lancer",
  "Boost Kicker",
  "Feather Sanctuary",
  "Winged Staff Priestess",
  "Gravity Grappler",
  "Barrage Brawler",
  "Holy Counterattack",
  "Focus",

  "Mammoth God's Colosseum",
  "Badb Catha",
  "Mithra, Daybreak Diety",
  "Fall from Grace",
  "Colosseum on High",
  "Chaht, Ringside Announcer",
  "Clash of Heroes",
  "Biofrabrication",
  "Sweet-Tooth Sleuth",
  "Bazooka Goblins",
  "Sentry Gate",

  "Izudia, Omen of Unkilling",
  "Spinaria, Keeper of Enigmas",
  "Apostle of Unkilling",
  "Morton the Manipulator",
  "Fairy Torrent",
  "Disciple of Unkilling",
  "Noah, Vengeful Puppeteer",
  "Mark of the Unkilling",
  "Servant of Unkilling",
  "Mechanical Bowman",
  "Flower Doll",
  "Automaton Soldier",
  "Mark of the Six",

  "Octrice, Omen of Usurpation",
  "Magna Legacy",
  "Apostle of Usurpation",
  "Empyreal Swordsman",
  "Confront Adversity",
  "Disciple of Usurpation",
  "Fervent Maachine Soldier",
  "Geno, Machine Artisan",
  "Servant of Usurpation",
  "Captain Meteo",
  "Gravikinetic Warrior",
  "Usurping Spineblade",
  "Avaritia",

  "Raio, Omen of Truth",
  "Lishenna, Omen of Destruction",
  "Apostle of Truth",
  "Safira, Synthetic Beast",
  "Destructive Refrain",
  "Iron Staff Mechanic",
  "Truth's Adjudication",
  "Monochromatic Destruction",
  "Disciple of Truth",
  "Disciple of Destruction",
  "Servant of Destruction",
  "Honest Cohort",
  "Metaproduction",

  "Galmieux, Omen of Disdain",
  "Electromagical Rhino",
  "Apostle of Disdain",
  "God Bullet Golem",
  "Disdainful Rending",
  "Disciple of Disdain",
  "Cursed Stone",
  "Amethyst Giant",
  "Servant of Disdain",
  "Silver Automaton",
  "Airship Whale",
  "Colossal Construct",
  "Total Domination",

  "Valnareik, Omen of Lust",
  "Rulenye, Omen of Silence",
  "Apostle of Lust",
  "Apostle of Silence",
  "Disciple of Lust",
  "Masked Puppet",
  "Wings of Lust",
  "Silent Purge",
  "Servant of Lust",
  "Servant of Silence",
  "Hamelin",
  "Embracing Wings",
  "Thundering Roar",

  "Marwynn, Omen of Repose",
  "Deus Ex Machina",
  "Apostle of Repose",
  "Hakrabi",
  "Ancient Protector",
  "Disciple of Repose",
  "Unidentified Subject",
  "Silver Cog Spinner",
  "Servant of Repose",
  "Demon's Epitaph",
  "The Saviors",
  "Realm of Repose",
  "Ancient Amplifier",

  "Mjerrabaine, Omen of One",
  "Gilnelise, Omen of Craving",
  "Apostle of Craving",
  "Lyrial, Archer Throne",
  "Feena, Dynamite Daredevil",
  "Rosa, Mech Wing Maiden",
  "Enlightenment",
  "Craving's Splendor",
  "Cat Cannoneer",
  "Steel Demolitionist",
  "Gliesaray",

  "Cassiopeia",
  "C.C. Woodland Witch",
  "Deepwood Anomaly",
  "King Elephant",
  "Fashionista Nelcha",
  "Spring-Green Protection",
  "Inviolable Verdancy",
  "Sukuna, Brave and Small",
  "Dolorblade Demon",
  "Elf Song",
  "Starry Elf",
  "Fita the Gentle Elf",
  "Dryad",
  "Beetle Warrior",
  "Ivy Spellbomb",

  "Mars, Silent Flame General",
  "Gawain of the Round Table",
  "Barbarossa",
  "Perseus",
  "Cyclone Blade",
  "Chivalrous Charge",
  "Shrouded Assassin",
  "Lord General Romeo",
  "Round Table Assembly",
  "Princess Juliet",
  "Flail Knight",
  "Pollux",
  "Tristan of the Round Table",
  "Armor of the Stars",

  "Wordwielder Ginger",
  "Giant Chimera",
  "Star Reader Stella",
  "Europa",
  "Chain of Calling",
  "Noble Instruction",
  "Freshman Lou",
  "Magic Illusionist",
  "Concentration",
  "Show of Loyalty",
  "Dazzling Healer",
  "Mage of Nightfall",
  "Astrologist of the Mist",
  "Magic Owl",
  "Starseer's Telescope",

  "Sibyl of the Waterwyrm",
  "Kallen, Crimson Yaksha",
  "Python",
  "Prime Dragon Keeper",
  "Star Phoenix",
  "Lightning Blast",
  "Guren Revolt",
  "Venomous Pucewyrm",
  "Cetus",
  "Dragonewt Fist",
  "Divine Tiger",
  "Dragonrearer Matilda",
  "Aqua Nerid",
  "Hippocampus",
  "Scaled Berserker",
  "Dragon's Nest",

  "Venomfang Medusa",
  "Howling Demon",
  "Demonlord Eachtar",
  "Lelouch, Leader of the Black Knights",
  "Stheno",
  "Trial of the Gorgons",
  "Fenrir",
  "Euryale",
  "Grave Desecration",
  "Emperor's Command",
  "Demonic Drummer",
  "Castor",
  "Frogbat",
  "Scorpius",
  "Venomous Bite",

  "Aether of the White Wing",
  "Dark Jeanne",
  "Zoe, Princess of Goldenia",
  "Andromeda",
  "Globe of the Starways",
  "Dark Charisma",
  "Star Priestess",
  "Calydonian Boar",
  "Star Torrent",
  "Starchaser Sprite",
  "Sister of Punishment",
  "Mist Shaman",
  "Octobishop",
  "Candelabra of Prayers",

  "Zodiac Demon",
  "Israfil",
  "Grimnir, War Cyclone",
  "Arriet, Soothing Harpist",
  "Staircase to Paradise",
  "Purehearted Singer",
  "Goblin Princess",
  "Mystic Ring",
  "Owlcat",
  "Mr. Full Moon",
  "Night's Way",

  "Beauty and the Beast",
  "Cosmos Fang",
  "Magical Fairy, Lilac",
  "Slade Blossoming Wolf",
  "Elf Twins' Assault",
  "Abby the Axe Girl",
  "Gerbera Bear",
  "Wood of Brambles",
  "Flower Princess",
  "Fen Sprite",
  "Tweedle Dum, Tweedle Dee",
  "Floral Breeze",
  "Woodland Band",
  "Cinderella",
  "Valiant Fencer",
  "Maisy, Red Riding Hood",
  "Amerro, Spear Knight",
  "Castle in the Sky",
  "Young Ogrehunter Momo",
  "Mach Knight",
  "Kiss of the Princess",
  "Rabbit Ear Attendant",
  "Old Man and Old Woman",
  "Bladed Hedgehog",
  "Ironwrought Defender",
  "Heroic Entry",
  "Wizardess of Oz",
  "Witch of Calamity, Millie Parfait",
  "Mystic King",
  "Falise, Leonardian Mage",
  "Milady, Mystic Queen",
  "Check",
  "Mr. Heinlein, Shadow Mage",
  "Magical Knight",
  "Gingerbread House",
  "It's a Sweets Buffet!",
  "Witch of Sweets",
  "Magical Rook",
  "Magical Bishop",
  "Blitz",
  "Witch's Cauldron",
  "The Cauldron of Calamity",
  "Jabberwock U",
  "Lævateinn Dragon",
  "Red Ragewyrm",
  "Draconir, Knuckle Dragon",
  "Tilting at Windmills",
  "Master of Draconic Arts",
  "Hammer Dragonewt",
  "Draconic Smash",
  "Elder Tortoise",
  "Trinity Dragon",
  "Dragon Summoner",
  "Lance Lizard",
  "Armor Burst",
  "Dark Alice",
  "Masquerade Ghost",
  "Odile, Black Swan",
  "Demonium, Punk Devil",
  "Baccherus, Peppy Ghostie",
  "Demon Maestro",
  "Trombone Devil",
  "Furtive Fangs",
  "Pumpkin Necromancer",
  "Parade Raven",
  "Mischievous Zombie",
  "Devilish Flautist",
  "Infernal Orchestration",
  "Princess Snow White",
  "Diamond Master",
  "Odette, White Swan",
  "Wingy Chirpy Gemstone",
  "Alice's Adventure",
  "White Knight",
  "Ruby Falcon",
  "March Hare's Teatime",
  "Tin Soldier",
  "Pinion Prince",
  "Birdkeeping Disciple",
  "Amethyst Lion",
  "Bejeweled Shrine",
  "Alice, Wonderland Explorer",
  "Angel of Chaos",
  "Rapunzel",
  "Seraph of Sin",
  "Garuel, Seraphic Leo",
  "Actress Feria",
  "Humpty Dumpty",
  "Winged Inversion",
  "Angel of Darkness",
  "Harbringer of the Night",
  "Eggsplosion",

  "Tazuna Hayakawa [Traccen Reception]",
  "Riko Kashimoto",
  "Aoi Kiryuin",
  "Silence Suzuka",
  "Smart Falcon",
  "Gold City",
  "Eat Fast! Yum Fast!",
  "Shinko Windy",
  "Eishin Flash",
  "Systematic Squats",
  "Marvelous Sunday",
  "Yukino Bijin",
  "Ines Fujin",
  "Taiki Shuttle",
  "Haru Urara",
  "Tokai Teio",
  "Narita Brian",
  "Winning Ticket",
  "Outrunning the Encroaching Heat",
  "Air Groove",
  "Hishi Amazon",
  "Trial Initiation",
  "Sirius Symboli",
  "Narita Taishin",
  "Symboli Rudolf",
  "Biko Pegasus",
  "Fuji Kiseki",
  "Agnes Tachyon",
  "Daiwa Scarlet",
  "Vodka",
  "Make! Some! NOISE!",
  "Zenno Rob Roy",
  "Agens Digital",
  "Lamplit Training of a Witch-to-Be",
  "Admire Vega",
  "Kawakami Princess",
  "Nakayama Festa",
  "Tosen Jordan",
  "Narita Top Road",
  "Special Week",
  "Oguri Cap",
  "Seiun Sky",
  "Champion's Passion",
  "King Halo",
  "Tamamo Cross",
  "Flowers for You",
  "Bamboo Memory",
  "Yaeno Muteki",
  "Grass Wonder",
  "Super Creek",
  "Hishi Akebono",
  "Maruzensky",
  "Rice Shower",
  "Nice Nature",
  "7 More Centimeters",
  "Fine Motion",
  "Mayano Top Gun",
  "My Solo Drawn to Raindrop Drums",
  "Curren Chan",
  "Twin Turbo",
  "Sakura Chiyono O",
  "Seeking the Pearl",
  "Matikanetannhauser",
  "Mejiro McQueen",
  "Gold Ship",
  "Ikuno Dictus",
  "The Will to Overtake",
  "Meisho Doto",
  "Mejiro Ryan",
  "Fate's Forecast",
  "Inari One",
  "Mejiro Dober",
  "Mejiro Ardan",
  "Mejiro Palmer",
  "T.M Opera O",
  "Riko Kashimoto [Planned Perfection]",
  "Close Knit Ambitions",
  "Take a Jab!",
  "Aoi Kiryuin [Trainers' Teamwork]",
  "Sasami Anshinzawa",
  "Tazuna Hayakawa",

  "Crystalia Tia",
  "White Wolf of Eldwood",
  "Elf Girl Liza",
  "Elf Knight Cynthia",
  "Grand Archer Seiwyn",
  "Crystalia Lily",
  "Baalt King of the Elves",
  "Elven Archery",
  "Dwarf Perfumer",
  "Elf Healer",
  "Forest Gigas",
  "Elf Bard",
  "Rose Deer",
  "Albert Levin Saber",
  "Alexander",
  "Amelia, Silver Paladin",
  "Leonidas",
  "White Paladin",
  "Jeno, Levin Vanguard",
  "Yurius, Levin Duke",
  "Whole-Souled Swing",
  "Swift Infiltrator",
  "Samurai",
  "Avant Blader",
  "Flame Soldier",
  "Gunner Maid Seria",
  "Daria Dimensional Witch",
  "Sun Oracle Pascale",
  "Anne, Belle oF Mysteria",
  "Grea the Dragonborn",
  "Rimewind",
  "Remi & Rami, Witchy Duo",
  "Shadow Witch",
  "Multipart Expirement",
  "Craig, Wizard of Mysteria",
  "Grand Gargoyle",
  "Witchbolt",
  "Magical Strategy",
  "Red-Hot Ritual",
  "Imperial Dragoon",
  "Dragonsong Flute",
  "Neptune",
  "Draconic Fervor",
  "Polyphonic Roar",
  "Siegfried",
  "Transmogrified Wyrm",
  "Dracomancer's Rites",
  "Wildfang Dragonewt",
  "Mushussu",
  "Dragontamer",
  "Twin-Headed Dragon",
  "Draconic Armor",
  "Vania, Vampire Princess",
  "Soul Dealer",
  "Underworld Watchman Khawy",
  "Azazel",
  "Vampiric Fortress",
  "Veight, Vampire Noble",
  "Trick Dullahan",
  "Precious Bloodfangs",
  "Mini Soul Devil",
  "Moriana the Bejeweled",
  "Demonic Hedonist",
  "Bone Chimera",
  "Necrocarnival",
  "Heavenly Aegis",
  "Enstatued Seraph",
  "Kaguya",
  "Tribunal of Good and Evil",
  "Elana's Prayer",
  "Radiance Angel",
  "Saphire Priestess",
  "Beastcall Aria",
  "Frog Cleric",
  "Sky Sprite",
  "Soul Collector",
  "Sledgehammer Exorcist",
  "Emerald Maiden",
  "Dark Angel Olivia",
  "Bahamut",
  "Demonic Simulacrum",
  "Archangel Reina",
  "Surefire Bullet",
  "Unicorn Dancer Unicorn",
  "Gourmet Emperor Khaiza",
  "Call of Cocytus",
  "Hamsa",
  "Sektor",
  "Dance of Death",

  "Skullfane",
  "Hare of Illusions",
  "Priest of the Cudgel",
  "Acolyte's Light",
  "Dual Flames",
  "Ardent Nun",
  "Guardian Nun",
  "Pinion Prayer",
  "Beastly Vow",
  "Queen Vampire",
  "Alucard",
  "Playful Necomancer",
  "Midnight Vampire",
  "Night Horde",
  "Lesser Mummy",
  "Lilith",
  "Undying Resentment",
  "Summon Bloodkin",
  "Fafnir",
  "Dragon Oracle",
  "Dragon Warrior",
  "Dragonewt Princess",
  "Dragonguard",
  "Roc",
  "Glint Dragon",
  "Dragonrider",
  "Seabrand Dragon",
  "Mythril Golem",
  "Rune Blade Summoner",
  "Demonflame Mage",
  "Insight",
  "Fire Chain",
  "Penguin Wizard",
  "Sammy Wizard's Apprentice",
  "Magic Missle",
  "Conjure Golem",
  "Tsubasa",
  "Latham, Vanguard Captain",
  "Floral Fencer",
  "Moonlight Assassin",
  "White General",
  "Fencer",
  "Oathless Knight",
  "Quickblader",
  "Unbridled Fury",
  "Aria, Fairy Princess",
  "Titania's Sanctuary",
  "Rose Gardener",
  "Waltzing Fairy",
  "Fairy Caster",
  "Treant",
  "Water Fairy",
  "Elf Wanderer",
  "Sylvan Justice",
  "Rose Queen",
  "Ancient Elf",
  "Rhinoceroach",
  "Robin Hood",
  "Silver Bolt",
  "Homecoming",
  "Elven Princess Mage",
  "Blessed Fairy Dancer",
  "Elf Child May",
  "Fairy Beast",
  "Noble Fairy",
  "Nature's Guidance",
  "Harvest Festival",
  "Elf Metallurgist",
  "Archer",
  "Fairy Whisperer",
  "Okami",
  "Mana Elk",
  "Fairy Circle",
  "Woodkin Curse",
  "Woodland Refuge",
  "Sea Queen Otohime",
  "Aurelia, Regal Saber",
  "Shadowed Assassin",
  "Frontguard General",
  "Alwida's Command",
  "Royal Banner",
  "Maid Leader",
  "Gemstaff Commander",
  "Sage Commander",
  "Swordsman",
  "Pompous Princess",
  "Ninja Master",
  "Arthurian Light",
  "Ninja Trainee",
  "Fervid Soldier",
  "Luminous Knight",
  "Veteran Lancer",
  "Navy Lieutenant",
  "Novice Trooper",
  "Forge Weaponry",
  "Onslaught",
  "Arch Summoner Erasmus",
  "Merlin",
  "Ancient Alchemist",
  "Arcane Enlightenment",
  "Dimension Shift",
  "Juno's Secret Laboratory",
  "Spectral Wizard",
  "Flame Destroyer",
  "Dragonbond Mage",
  "Golem Protection",
  "Alchemical Lore",
  "Fate's Hand",
  "Price of Magic",
  "Runic Guardian",
  "Crafty Warlock",
  "Lightning Shooter",
  "Wind Blast",
  "Sorcery Cache",
  "Fiery Embrace",
  "Alchemist's Workshop",
  "Teachings of Creation",
  "Dark Dragoon Forte",
  "Zirnitra",
  "Aiela, Dragon Knight",
  "Genesis Dragon",
  "Shapeshifting Mage",
  "Phoenix Roost",
  "Wyvern Cavalier",
  "Dragonewt Scholar",
  "Shenlong",
  "Imprisoned Dragon",
  "Conflagration",
  "Serpent's Wrath",
  "Wyrm Spire",
  "Ivory Dragon",
  "Fire Lizard",
  "Ace Dragoon",
  "Mist Dragon",
  "Dread Dragon",
  "Blazing Breath",
  "Dragon Wings",
  "Dragon Emissary",
  "Cerberus",
  "Lord Atomy",
  "Medusa",
  "Righteous Devil",
  "Mordecai the Duelist",
  "Dire Bond",
  "Hell's Unleasher",
  "Crazed Executioner",
  "Dark Summoner",
  "Dark General",
  "Phantom Howl",
  "Death's Breath",
  "Soul Conversion",
  "Skeleton Fighter",
  "Ambling Wraith",
  "Spectre",
  "Spartoi Sergeant",
  "Rabbit Necromancer",
  "Wardrobe Raider",
  "Undead King",
  "Razory Claw",
  "Moon Al-mi'raj",
  "Jeanne d'Arc",
  "Arch Priestess Laelia",
  "Themis's Decree",
  "Chorus of Prayer",
  "Sacred Plea",
  "Temple Defender",
  "Prism Priestess",
  "Cleric Lancer",
  "Shrine Knight Maiden",
  "Blackened Scripture",
  "Dark Offering",
  "Holy Sentinel",
  "Cruel Priestess",
  "Sister Initiate",
  "Mainyu",
  "Snake Priestess",
  "Curate",
  "Hallowed Dogma",
  "Guardian Sun",
  "Death Sentence",
  "Gabriel",
  "Lucifer",
  "Flame and Glass",
  "Urd",
  "Wind God",
  "Gilgamesh",
  "Bellringer Angel",
  "Altered Fate",
  "Path to Purgatory",
  "Lizardman",
  "Goblinmount Demon",
  "Harnessed Flame",
  "Harnessed Glass",
  "Demonic Strike",
  "Execution",
  "Trail of Light",
  "Goblin",
  "Fighter",
  "Goliath",
  "Angelic Sword Maiden",
  "Healing Angel",
  "Angelic Snipe",
  "Angelic Barrage",
];

export const set7 = [
  "Ladica, the Stoneclaw",
  "Cynthia, the Queen's Blade",
  "Primal Giant",
  "Setus, the Beastblade",
  "Send 'Em Packing",
  "Blossom Spirit",
  "Avatar of Fruition",
  "Divine Smithing",
  "Chesire Cat",
  "Ghastly Treant",
  "Forest Hermit",
  "Marvelously Mad Hatter",
  "Fertile Aether",

  "Bayleon, Sovereign Light",
  "Mistolina, Forest Princess",
  "Tsubaki of the Demon Blade",
  "Leod, the Crescent Blade",
  "King's Might",
  "Troya, Thunder of Hagelberg",
  "Valse, Champion Deadeye",
  "Princess's Strike",
  "Swift Tigress",
  "Lupine Axeman",
  "Dauntless Commander",
  "Tempered Aether",
  "Elegance in Action",

  "Tetra, Sapphire Rebel",
  "Belphomet, Lord of Aiolon",
  "Riley, Hydroshaman",
  "Eleanor, Cosmic Flower",
  "Delta Cannon",
  "Displacer Bot",
  "Mechanized Lifeform",
  "Splendid Conjury",
  "Mechastaff Sorcerer",
  "Prototype Warrior",
  "Magiblade Witch",
  "Presto Chango",
  "Sagacious Core",

  "Valdain, Cursed Shadow",
  "Neptune, Tidemistress",
  "Wildfire Tyrannosaur",
  "Marion, Elegant Dragonewt",
  "Shadow's Corrosion",
  "Bubbleborne Mermaid",
  "Hoarfrost Triceratops",
  "Whirlwind Pteranodon",
  "Dragonewt Needler",
  "Lightning Velociraptor",
  "Doting Dragoneer",
  "Boomfish",
  "Feral Aether",

  "Mono, Garnet Rebel",
  "Kudlak",
  "Aenea, Amethyst Rebel",
  "Doublame, Duke and Dame",
  "Alpha Drive",
  "Nicola, Forbidden Strength",
  "Hellblaze Demon",
  "Forbidden Art",
  "Robozombie",
  "Bone Drone",
  "Berserk Demon",
  "Ghostwriter",
  "Sanguine Core",

  "Limonia, Flawed Saint",
  "Lapis, Glorious Seraph",
  "Father Refinement",
  "Marione, Light of Balance",
  "Augmentation Bestowal",
  "Bunny-Eared Administrator",
  "Robofalcon",
  "Marcotte, Heretical Sister",
  "Ironknuckle Nun",
  "Dark Bishop",
  "Meowskers, Ruff-Tuff Major",
  "Saintly Core",
  "Meowskers Ambush!",

  "Technolord",
  "Viridia Magna",
  "Mechawing Angel",
  "Desert Pathfinder",
  "Maisha, Hero of Purgation",
  "Robogoblin",
  "Colorful Cook",
  "Purgation's Blade",
  "Aldis, Trendsetting Seraph",
  "Mechagun Wielder",
  "Extreme Carrot",
];

export const setIDOL = [
  "Uzuki Shimamura [P.C.S.]",
  "Kyoko Igarashi [P.C.S.]",
  "Miho Kohinata [P.C.S.]",
  "Chika Yokoyama",
  "Momoka Sakurai",
  "Akiha Ikebukuro",
  "Nene Kurihara",
  "Karin Domyoji",

  "Rin Shibuya [Triad Primus]",
  "Nao Kamiya [Over the Rainbow]",
  "Karen Hojo [Song for Life]",
  "Yasuha Okazaki",
  "Yukimi Sajo",
  "Kako Takafuji",
  "Chizuru Matsuo",
  "Seira Mizuki",

  "Mio Honda [Positive Passion]",
  "Aiko Takamori [Handmade Hapiness]",
  "Akane Hino [Positive Passion]",
  "Kaoru Ryuzaki",
  "Suzuho Ueda",
  "Miria Akagi",
  "Miu Yaguchi",
  "Kumiko Matsuyama",

  "Aiko Takamori",
  "Miku Maekawa",
  "Yuzu Kitami",
  "Anastasia",
  "Brand New Beat",
  "Shinobu Kudo",
  "Yumi Aiba",
  "Goddess by the Sunlit Sea",
  "Honoka Ayase",
  "Azuki Momoi",
  "Kana Imai",
  "Otaha Umeki",
  "A Single Vessel",

  "Kyoko Igarashi",
  "Nagi Hisakawa",
  "Rin Shibuya",
  "Mio Honda",
  "Uzuki Shimamura",
  "Anzu Futaba",
  "Karen Hojo",
  "Sparkling Days",
  "Nao Kamiya",
  "Mayu Sakuma",
  "Kirari Moroboshi",
  "Miho Kohinata",
  "Angelic Maid",

  "Mizuki Kawashima",
  "Shiki Ichinose",
  "Syuko Shiomi",
  "Kanade Hayami",
  "Center Street",
  "Hina Araki",
  "Frederica Miyamoto",
  "Precocious Little Devil",
  "Sarina Matsumoto",
  "Rika Jougasaki",
  "Sae Kobayakawa",
  "Tomoe Murakami",
  "Full Bloom Panorama",

  "Akari Tsujino",
  "Yui Ohtsuki",
  "Fumika Sagisawa",
  "Akira Sunazuka",
  "Tsukasa Kiryu",
  "Arisu Tachibana",
  "Yuka Nakano",
  "Unbound Emotion",
  "Tokiko Zaizen",
  "Noriko Shiina",
  "Yukari Mizumoto",
  "Noa Takamine",
  "Mode Estivale",

  "Ranko Kanzaki",
  "Sachiko Koshimizu",
  "Takumi Mukai",
  "Chitose Kurosaki",
  "Whispers of a Dream",
  "Chiyo Shirayuki",
  "Aki Yamato",
  "My Life, My Sounds",
  "Ryo Matsunaga",
  "Mirei Hayasaka",
  "Rina Fujimoto",
  "Syoko Hoshi",
  "Last Daylight",

  "Kaede Takagaki",
  "Shin Sato",
  "Nana Abe",
  "Akane Hino",
  "Classroom Lily",
  "Risa Matoba",
  "Haru Yuuki",
  "Psychic Maiden",
  "Natalia",
  "Shizuku Oikawa",
  "Layla",
  "Sanae Katagiri",
  "Winter Night Prayer",

  "New Generations",
  "New Wave",
  "Master Trainer",
  "Expert Trainer",
  "Trainer",
  "Rookie Trainer",
];

export const set6 = [
  "Lymaga, Forest Champion",
  "Amataz, Fairy Blader",
  "Greenbrier Elf",
  "Wildwood Matriarch",
  "Fairy Dragon",
  "Woodland Cleaver",
  "Assault Jaguar",
  "Spiritshine",
  "Greenwood Guardian",
  "Crossbow Sniper",
  "Mallet Monkey",
  "Elven Sentry",
  "Synchronized Slash",
  "Kagemitsu, Matchless Blade",
  "Ralmia, Sonic Racer",
  "Steadfast Samurai",
  "Hero of Antiquity",
  "Courtly Dance",
  "Quickdraw Maven",
  "Twinsword Master",
  "Grand Acquisition",
  "Samurai Outlaw",
  "Adept Thief",
  "Petalwink Paladin",
  "Levin Scholar",
  "Breakneck Draw",
  "Kuon, Founder of Onmyodo",
  "Mysteria, Magic Founder",
  "Curse Crafter",
  "Hulking Giant",
  "Shikigami Summons",
  "Demoncaller",
  "Traditional Sorcerer",
  "Crimson Meteor Storm",
  "Talisman Disciple",
  "Charming Gentlemouse",
  "Passionate Potioneer",
  "Golem's Rampage",
  "Mirror of Truth",
  "Garyu, Surpreme Dragonkin",
  "Filene, Whitefrost Dragonewt",
  "Phoenix Empress",
  "Wyrm God of the Skies",
  "Whitefrost Whisper",
  "Ice Dancing Dragonewt",
  "Jadelong Tactician",
  "Aquascale Stalwart",
  "Swordwhip Dragoon",
  "Dragonblader",
  "Trident Merman",
  "Dragon Chef",
  "Flamewinged Might",
  "Ginsetsu, Great Fox",
  "Aragavy the Berserker",
  "Shuten-Doji",
  "Bear Pelt Warrior",
  "Yuzuki, Righteous Demon",
  "Kasha",
  "Cougar Pelt Warrior",
  "Unleash the Nightmare",
  "Zashiki-Warashi",
  "Antelope Pelt Warrior",
  "Rookie Succubus",
  "Demonic Procession",
  "Berserker's Pelt",
  "Wilbert, Grand Knight",
  "Karula, Arts Master",
  "Saintly Leader",
  "Phantom Blade Wielder",
  "Manifest Devotion",
  "Holy Lancer",
  "Boost Kicker",
  "Feather Sanctuary",
  "Winged Staff Priestess",
  "Gravity Grappler",
  "Barrage Brawler",
  "Holy Counterattack",
  "Focus",
  "Mammoth God's Colosseum",
  "Badb Catha",
  "Mithra, Daybreak Diety",
  "Fall from Grace",
  "Colosseum on High",
  "Chaht, Ringside Announcer",
  "Clash of Heroes",
  "Biofrabrication",
  "Sweet-Tooth Sleuth",
  "Bazooka Goblins",
  "Sentry Gate",
];

export const set5 = [
  "Izudia, Omen of Unkilling",
  "Spinaria, Keeper of Enigmas",
  "Apostle of Unkilling",
  "Morton the Manipulator",
  "Fairy Torrent",
  "Disciple of Unkilling",
  "Noah, Vengeful Puppeteer",
  "Mark of the Unkilling",
  "Servant of Unkilling",
  "Mechanical Bowman",
  "Flower Doll",
  "Automaton Soldier",
  "Mark of the Six",

  "Octrice, Omen of Usurpation",
  "Magna Legacy",
  "Apostle of Usurpation",
  "Empyreal Swordsman",
  "Confront Adversity",
  "Disciple of Usurpation",
  "Fervent Maachine Soldier",
  "Geno, Machine Artisan",
  "Servant of Usurpation",
  "Captain Meteo",
  "Gravikinetic Warrior",
  "Usurping Spineblade",
  "Avaritia",

  "Raio, Omen of Truth",
  "Lishenna, Omen of Destruction",
  "Apostle of Truth",
  "Safira, Synthetic Beast",
  "Destructive Refrain",
  "Iron Staff Mechanic",
  "Truth's Adjudication",
  "Monochromatic Destruction",
  "Disciple of Truth",
  "Disciple of Destruction",
  "Servant of Destruction",
  "Honest Cohort",
  "Metaproduction",

  "Galmieux, Omen of Disdain",
  "Electromagical Rhino",
  "Apostle of Disdain",
  "God Bullet Golem",
  "Disdainful Rending",
  "Disciple of Disdain",
  "Cursed Stone",
  "Amethyst Giant",
  "Servant of Disdain",
  "Silver Automaton",
  "Airship Whale",
  "Colossal Construct",
  "Total Domination",

  "Valnareik, Omen of Lust",
  "Rulenye, Omen of Silence",
  "Apostle of Lust",
  "Apostle of Silence",
  "Disciple of Lust",
  "Masked Puppet",
  "Wings of Lust",
  "Silent Purge",
  "Servant of Lust",
  "Servant of Silence",
  "Hamelin",
  "Embracing Wings",
  "Thundering Roar",

  "Marwynn, Omen of Repose",
  "Deus Ex Machina",
  "Apostle of Repose",
  "Hakrabi",
  "Ancient Protector",
  "Disciple of Repose",
  "Unidentified Subject",
  "Silver Cog Spinner",
  "Servant of Repose",
  "Demon's Epitaph",
  "The Saviors",
  "Realm of Repose",
  "Ancient Amplifier",

  "Mjerrabaine, Omen of One",
  "Gilnelise, Omen of Craving",
  "Apostle of Craving",
  "Lyrial, Archer Throne",
  "Feena, Dynamite Daredevil",
  "Rosa, Mech Wing Maiden",
  "Enlightenment",
  "Craving's Splendor",
  "Cat Cannoneer",
  "Steel Demolitionist",
  "Gliesaray",
];

export const set4 = [
  "Cassiopeia",
  "C.C. Woodland Witch",
  "Deepwood Anomaly",
  "King Elephant",
  "Fashionista Nelcha",
  "Spring-Green Protection",
  "Inviolable Verdancy",
  "Sukuna, Brave and Small",
  "Dolorblade Demon",
  "Elf Song",
  "Starry Elf",
  "Fita the Gentle Elf",
  "Dryad",
  "Beetle Warrior",
  "Ivy Spellbomb",

  "Mars, Silent Flame General",
  "Gawain of the Round Table",
  "Barbarossa",
  "Perseus",
  "Cyclone Blade",
  "Chivalrous Charge",
  "Shrouded Assassin",
  "Lord General Romeo",
  "Round Table Assembly",
  "Princess Juliet",
  "Flail Knight",
  "Pollux",
  "Tristan of the Round Table",
  "Armor of the Stars",

  "Wordwielder Ginger",
  "Giant Chimera",
  "Star Reader Stella",
  "Europa",
  "Chain of Calling",
  "Noble Instruction",
  "Freshman Lou",
  "Magic Illusionist",
  "Concentration",
  "Show of Loyalty",
  "Dazzling Healer",
  "Mage of Nightfall",
  "Astrologist of the Mist",
  "Magic Owl",
  "Starseer's Telescope",

  "Sibyl of the Waterwyrm",
  "Kallen, Crimson Yaksha",
  "Python",
  "Prime Dragon Keeper",
  "Star Phoenix",
  "Lightning Blast",
  "Guren Revolt",
  "Venomous Pucewyrm",
  "Cetus",
  "Dragonewt Fist",
  "Divine Tiger",
  "Dragonrearer Matilda",
  "Aqua Nerid",
  "Hippocampus",
  "Scaled Berserker",
  "Dragon's Nest",

  "Venomfang Medusa",
  "Howling Demon",
  "Demonlord Eachtar",
  "Lelouch, Leader of the Black Knights",
  "Stheno",
  "Trial of the Gorgons",
  "Fenrir",
  "Euryale",
  "Grave Desecration",
  "Emperor's Command",
  "Demonic Drummer",
  "Castor",
  "Frogbat",
  "Scorpius",
  "Venomous Bite",

  "Aether of the White Wing",
  "Dark Jeanne",
  "Zoe, Princess of Goldenia",
  "Andromeda",
  "Globe of the Starways",
  "Dark Charisma",
  "Star Priestess",
  "Calydonian Boar",
  "Star Torrent",
  "Starchaser Sprite",
  "Sister of Punishment",
  "Mist Shaman",
  "Octobishop",
  "Candelabra of Prayers",

  "Zodiac Demon",
  "Israfil",
  "Grimnir, War Cyclone",
  "Arriet, Soothing Harpist",
  "Staircase to Paradise",
  "Purehearted Singer",
  "Goblin Princess",
  "Mystic Ring",
  "Owlcat",
  "Mr. Full Moon",
  "Night's Way",
];

export const set3 = [
  "Beauty and the Beast",
  "Cosmos Fang",
  "Magical Fairy, Lilac",
  "Slade Blossoming Wolf",
  "Elf Twins' Assault",
  "Abby the Axe Girl",
  "Gerbera Bear",
  "Wood of Brambles",
  "Flower Princess",
  "Fen Sprite",
  "Tweedle Dum, Tweedle Dee",
  "Floral Breeze",
  "Woodland Band",
  "Cinderella",
  "Valiant Fencer",
  "Maisy, Red Riding Hood",
  "Amerro, Spear Knight",
  "Castle in the Sky",
  "Young Ogrehunter Momo",
  "Mach Knight",
  "Kiss of the Princess",
  "Rabbit Ear Attendant",
  "Old Man and Old Woman",
  "Bladed Hedgehog",
  "Ironwrought Defender",
  "Heroic Entry",
  "Wizardess of Oz",
  "Witch of Calamity, Millie Parfait",
  "Mystic King",
  "Falise, Leonardian Mage",
  "Milady, Mystic Queen",
  "Check",
  "Mr. Heinlein, Shadow Mage",
  "Magical Knight",
  "Gingerbread House",
  "It's a Sweets Buffet!",
  "Witch of Sweets",
  "Magical Rook",
  "Magical Bishop",
  "Blitz",
  "Witch's Cauldron",
  "The Cauldron of Calamity",
  "Jabberwock U",
  "Lævateinn Dragon",
  "Red Ragewyrm",
  "Draconir, Knuckle Dragon",
  "Tilting at Windmills",
  "Master of Draconic Arts",
  "Hammer Dragonewt",
  "Draconic Smash",
  "Elder Tortoise",
  "Trinity Dragon",
  "Dragon Summoner",
  "Lance Lizard",
  "Armor Burst",
  "Dark Alice",
  "Masquerade Ghost",
  "Odile, Black Swan",
  "Demonium, Punk Devil",
  "Baccherus, Peppy Ghostie",
  "Demon Maestro",
  "Trombone Devil",
  "Furtive Fangs",
  "Pumpkin Necromancer",
  "Parade Raven",
  "Mischievous Zombie",
  "Devilish Flautist",
  "Infernal Orchestration",
  "Princess Snow White",
  "Diamond Master",
  "Odette, White Swan",
  "Wingy Chirpy Gemstone",
  "Alice's Adventure",
  "White Knight",
  "Ruby Falcon",
  "March Hare's Teatime",
  "Tin Soldier",
  "Pinion Prince",
  "Birdkeeping Disciple",
  "Amethyst Lion",
  "Bejeweled Shrine",
  "Alice, Wonderland Explorer",
  "Angel of Chaos",
  "Rapunzel",
  "Seraph of Sin",
  "Garuel, Seraphic Leo",
  "Actress Feria",
  "Humpty Dumpty",
  "Winged Inversion",
  "Angel of Darkness",
  "Harbringer of the Night",
  "Eggsplosion",
];

export const setUMA = [
  "Tazuna Hayakawa [Traccen Reception]",
  "Riko Kashimoto",
  "Aoi Kiryuin",
  "Silence Suzuka",
  "Smart Falcon",
  "Gold City",
  "Eat Fast! Yum Fast!",
  "Shinko Windy",
  "Eishin Flash",
  "Systematic Squats",
  "Marvelous Sunday",
  "Yukino Bijin",
  "Ines Fujin",
  "Taiki Shuttle",
  "Haru Urara",
  "Tokai Teio",
  "Narita Brian",
  "Winning Ticket",
  "Outrunning the Encroaching Heat",
  "Air Groove",
  "Hishi Amazon",
  "Trial Initiation",
  "Sirius Symboli",
  "Narita Taishin",
  "Symboli Rudolf",
  "Biko Pegasus",
  "Fuji Kiseki",
  "Agnes Tachyon",
  "Daiwa Scarlet",
  "Vodka",
  "Make! Some! NOISE!",
  "Zenno Rob Roy",
  "Agens Digital",
  "Lamplit Training of a Witch-to-Be",
  "Admire Vega",
  "Kawakami Princess",
  "Nakayama Festa",
  "Tosen Jordan",
  "Narita Top Road",
  "Special Week",
  "Oguri Cap",
  "Seiun Sky",
  "Champion's Passion",
  "King Halo",
  "Tamamo Cross",
  "Flowers for You",
  "Bamboo Memory",
  "Yaeno Muteki",
  "Grass Wonder",
  "Super Creek",
  "Hishi Akebono",
  "Maruzensky",
  "Rice Shower",
  "Nice Nature",
  "7 More Centimeters",
  "Fine Motion",
  "Mayano Top Gun",
  "My Solo Drawn to Raindrop Drums",
  "Curren Chan",
  "Twin Turbo",
  "Sakura Chiyono O",
  "Seeking the Pearl",
  "Matikanetannhauser",
  "Mejiro McQueen",
  "Gold Ship",
  "Ikuno Dictus",
  "The Will to Overtake",
  "Meisho Doto",
  "Mejiro Ryan",
  "Fate's Forecast",
  "Inari One",
  "Mejiro Dober",
  "Mejiro Ardan",
  "Mejiro Palmer",
  "T.M Opera O",
  "Riko Kashimoto [Planned Perfection]",
  "Close Knit Ambitions",
  "Take a Jab!",
  "Aoi Kiryuin [Trainers' Teamwork]",
  "Sasami Anshinzawa",
  "Tazuna Hayakawa",
];

export const set2 = [
  "Crystalia Tia",
  "White Wolf of Eldwood",
  "Elf Girl Liza",
  "Elf Knight Cynthia",
  "Grand Archer Seiwyn",
  "Crystalia Lily",
  "Baalt King of the Elves",
  "Elven Archery",
  "Dwarf Perfumer",
  "Elf Healer",
  "Forest Gigas",
  "Elf Bard",
  "Rose Deer",
  "Albert Levin Saber",
  "Alexander",
  "Amelia, Silver Paladin",
  "Leonidas",
  "White Paladin",
  "Jeno, Levin Vanguard",
  "Yurius, Levin Duke",
  "Whole-Souled Swing",
  "Swift Infiltrator",
  "Samurai",
  "Avant Blader",
  "Flame Soldier",
  "Gunner Maid Seria",
  "Daria Dimensional Witch",
  "Sun Oracle Pascale",
  "Anne, Belle oF Mysteria",
  "Grea the Dragonborn",
  "Rimewind",
  "Remi & Rami, Witchy Duo",
  "Shadow Witch",
  "Multipart Expirement",
  "Craig, Wizard of Mysteria",
  "Grand Gargoyle",
  "Witchbolt",
  "Magical Strategy",
  "Red-Hot Ritual",
  "Imperial Dragoon",
  "Dragonsong Flute",
  "Neptune",
  "Draconic Fervor",
  "Polyphonic Roar",
  "Siegfried",
  "Transmogrified Wyrm",
  "Dracomancer's Rites",
  "Wildfang Dragonewt",
  "Mushussu",
  "Dragontamer",
  "Twin-Headed Dragon",
  "Draconic Armor",
  "Vania, Vampire Princess",
  "Soul Dealer",
  "Underworld Watchman Khawy",
  "Azazel",
  "Vampiric Fortress",
  "Veight, Vampire Noble",
  "Trick Dullahan",
  "Precious Bloodfangs",
  "Mini Soul Devil",
  "Moriana the Bejeweled",
  "Demonic Hedonist",
  "Bone Chimera",
  "Necrocarnival",
  "Heavenly Aegis",
  "Enstatued Seraph",
  "Kaguya",
  "Tribunal of Good and Evil",
  "Elana's Prayer",
  "Radiance Angel",
  "Saphire Priestess",
  "Beastcall Aria",
  "Frog Cleric",
  "Sky Sprite",
  "Soul Collector",
  "Sledgehammer Exorcist",
  "Emerald Maiden",
  "Dark Angel Olivia",
  "Bahamut",
  "Demonic Simulacrum",
  "Archangel Reina",
  "Surefire Bullet",
  "Unicorn Dancer Unicorn",
  "Gourmet Emperor Khaiza",
  "Call of Cocytus",
  "Hamsa",
  "Sektor",
  "Dance of Death",
];

export const set1 = [
  "Skullfane",
  "Hare of Illusions",
  "Priest of the Cudgel",
  "Acolyte's Light",
  "Dual Flames",
  "Ardent Nun",
  "Guardian Nun",
  "Pinion Prayer",
  "Beastly Vow",
  "Queen Vampire",
  "Alucard",
  "Playful Necomancer",
  "Midnight Vampire",
  "Night Horde",
  "Lesser Mummy",
  "Lilith",
  "Undying Resentment",
  "Summon Bloodkin",
  "Fafnir",
  "Dragon Oracle",
  "Dragon Warrior",
  "Dragonewt Princess",
  "Dragonguard",
  "Roc",
  "Glint Dragon",
  "Dragonrider",
  "Seabrand Dragon",
  "Mythril Golem",
  "Rune Blade Summoner",
  "Demonflame Mage",
  "Insight",
  "Fire Chain",
  "Penguin Wizard",
  "Sammy Wizard's Apprentice",
  "Magic Missle",
  "Conjure Golem",
  "Tsubasa",
  "Latham, Vanguard Captain",
  "Floral Fencer",
  "Moonlight Assassin",
  "White General",
  "Fencer",
  "Oathless Knight",
  "Quickblader",
  "Unbridled Fury",
  "Aria, Fairy Princess",
  "Titania's Sanctuary",
  "Rose Gardener",
  "Waltzing Fairy",
  "Fairy Caster",
  "Treant",
  "Water Fairy",
  "Elf Wanderer",
  "Sylvan Justice",
  "Rose Queen",
  "Ancient Elf",
  "Rhinoceroach",
  "Robin Hood",
  "Silver Bolt",
  "Homecoming",
  "Elven Princess Mage",
  "Blessed Fairy Dancer",
  "Elf Child May",
  "Fairy Beast",
  "Noble Fairy",
  "Nature's Guidance",
  "Harvest Festival",
  "Elf Metallurgist",
  "Archer",
  "Fairy Whisperer",
  "Okami",
  "Mana Elk",
  "Fairy Circle",
  "Woodkin Curse",
  "Woodland Refuge",
  "Sea Queen Otohime",
  "Aurelia, Regal Saber",
  "Shadowed Assassin",
  "Frontguard General",
  "Alwida's Command",
  "Royal Banner",
  "Maid Leader",
  "Gemstaff Commander",
  "Sage Commander",
  "Swordsman",
  "Pompous Princess",
  "Ninja Master",
  "Arthurian Light",
  "Ninja Trainee",
  "Fervid Soldier",
  "Luminous Knight",
  "Veteran Lancer",
  "Navy Lieutenant",
  "Novice Trooper",
  "Forge Weaponry",
  "Onslaught",
  "Arch Summoner Erasmus",
  "Merlin",
  "Ancient Alchemist",
  "Arcane Enlightenment",
  "Dimension Shift",
  "Juno's Secret Laboratory",
  "Spectral Wizard",
  "Flame Destroyer",
  "Dragonbond Mage",
  "Golem Protection",
  "Alchemical Lore",
  "Fate's Hand",
  "Price of Magic",
  "Runic Guardian",
  "Crafty Warlock",
  "Lightning Shooter",
  "Wind Blast",
  "Sorcery Cache",
  "Fiery Embrace",
  "Alchemist's Workshop",
  "Teachings of Creation",
  "Dark Dragoon Forte",
  "Zirnitra",
  "Aiela, Dragon Knight",
  "Genesis Dragon",
  "Shapeshifting Mage",
  "Phoenix Roost",
  "Wyvern Cavalier",
  "Dragonewt Scholar",
  "Shenlong",
  "Imprisoned Dragon",
  "Conflagration",
  "Serpent's Wrath",
  "Wyrm Spire",
  "Ivory Dragon",
  "Fire Lizard",
  "Ace Dragoon",
  "Mist Dragon",
  "Dread Dragon",
  "Blazing Breath",
  "Dragon Wings",
  "Dragon Emissary",
  "Cerberus",
  "Lord Atomy",
  "Medusa",
  "Righteous Devil",
  "Mordecai the Duelist",
  "Dire Bond",
  "Hell's Unleasher",
  "Crazed Executioner",
  "Dark Summoner",
  "Dark General",
  "Phantom Howl",
  "Death's Breath",
  "Soul Conversion",
  "Skeleton Fighter",
  "Ambling Wraith",
  "Spectre",
  "Spartoi Sergeant",
  "Rabbit Necromancer",
  "Wardrobe Raider",
  "Undead King",
  "Razory Claw",
  "Moon Al-mi'raj",
  "Jeanne d'Arc",
  "Arch Priestess Laelia",
  "Themis's Decree",
  "Chorus of Prayer",
  "Sacred Plea",
  "Temple Defender",
  "Prism Priestess",
  "Cleric Lancer",
  "Shrine Knight Maiden",
  "Blackened Scripture",
  "Dark Offering",
  "Holy Sentinel",
  "Cruel Priestess",
  "Sister Initiate",
  "Mainyu",
  "Snake Priestess",
  "Curate",
  "Hallowed Dogma",
  "Guardian Sun",
  "Death Sentence",
  "Gabriel",
  "Lucifer",
  "Flame and Glass",
  "Urd",
  "Wind God",
  "Gilgamesh",
  "Bellringer Angel",
  "Altered Fate",
  "Path to Purgatory",
  "Lizardman",
  "Goblinmount Demon",
  "Harnessed Flame",
  "Harnessed Glass",
  "Demonic Strike",
  "Execution",
  "Trail of Light",
  "Goblin",
  "Fighter",
  "Goliath",
  "Angelic Sword Maiden",
  "Healing Angel",
  "Angelic Snipe",
  "Angelic Barrage",
];

export const forest = [
  "Ladica, the Stoneclaw",
  "Cynthia, the Queen's Blade",
  "Primal Giant",
  "Setus, the Beastblade",
  "Send 'Em Packing",
  "Blossom Spirit",
  "Avatar of Fruition",
  "Divine Smithing",
  "Chesire Cat",
  "Ghastly Treant",
  "Forest Hermit",
  "Marvelously Mad Hatter",
  "Fertile Aether",

  "Momoka Sakurai",
  "Aiko Takamori [Handmade Hapiness]",
  "Suzuho Ueda",
  "Aiko Takamori",
  "Miku Maekawa",
  "Yuzu Kitami",
  "Anastasia",
  "Brand New Beat",
  "Shinobu Kudo",
  "Yumi Aiba",
  "Goddess by the Sunlit Sea",
  "Honoka Ayase",
  "Azuki Momoi",
  "Kana Imai",
  "Otaha Umeki",
  "A Single Vessel",

  "Lymaga, Forest Champion",
  "Amataz, Fairy Blader",
  "Greenbrier Elf",
  "Wildwood Matriarch",
  "Fairy Dragon",
  "Woodland Cleaver",
  "Assault Jaguar",
  "Spiritshine",
  "Greenwood Guardian",
  "Crossbow Sniper",
  "Mallet Monkey",
  "Elven Sentry",
  "Synchronized Slash",

  "Izudia, Omen of Unkilling",
  "Spinaria, Keeper of Enigmas",
  "Apostle of Unkilling",
  "Morton the Manipulator",
  "Fairy Torrent",
  "Disciple of Unkilling",
  "Noah, Vengeful Puppeteer",
  "Mark of the Unkilling",
  "Servant of Unkilling",
  "Mechanical Bowman",
  "Flower Doll",
  "Automaton Soldier",
  "Mark of the Six",

  "Cassiopeia",
  "C.C. Woodland Witch",
  "Deepwood Anomaly",
  "King Elephant",
  "Fashionista Nelcha",
  "Spring-Green Protection",
  "Inviolable Verdancy",
  "Sukuna, Brave and Small",
  "Dolorblade Demon",
  "Elf Song",
  "Starry Elf",
  "Fita the Gentle Elf",
  "Dryad",
  "Beetle Warrior",
  "Ivy Spellbomb",
  "Beauty and the Beast",
  "Cosmos Fang",
  "Magical Fairy, Lilac",
  "Slade Blossoming Wolf",
  "Elf Twins' Assault",
  "Abby the Axe Girl",
  "Gerbera Bear",
  "Wood of Brambles",
  "Flower Princess",
  "Fen Sprite",
  "Tweedle Dum, Tweedle Dee",
  "Floral Breeze",
  "Woodland Band",
  "Silence Suzuka",
  "Smart Falcon",
  "Gold City",
  "Eat Fast! Yum Fast!",
  "Shinko Windy",
  "Eishin Flash",
  "Systematic Squats",
  "Marvelous Sunday",
  "Yukino Bijin",
  "Ines Fujin",
  "Taiki Shuttle",
  "Haru Urara",
  "Crystalia Tia",
  "White Wolf of Eldwood",
  "Elf Girl Liza",
  "Elf Knight Cynthia",
  "Grand Archer Seiwyn",
  "Crystalia Lily",
  "Baalt King of the Elves",
  "Elven Archery",
  "Dwarf Perfumer",
  "Elf Healer",
  "Forest Gigas",
  "Elf Bard",
  "Rose Deer",
  "Aria, Fairy Princess",
  "Titania's Sanctuary",
  "Rose Gardener",
  "Waltzing Fairy",
  "Fairy Caster",
  "Treant",
  "Water Fairy",
  "Elf Wanderer",
  "Sylvan Justice",
  "Rose Queen",
  "Ancient Elf",
  "Rhinoceroach",
  "Robin Hood",
  "Silver Bolt",
  "Homecoming",
  "Elven Princess Mage",
  "Blessed Fairy Dancer",
  "Elf Child May",
  "Fairy Beast",
  "Noble Fairy",
  "Nature's Guidance",
  "Harvest Festival",
  "Elf Metallurgist",
  "Archer",
  "Fairy Whisperer",
  "Okami",
  "Mana Elk",
  "Fairy Circle",
  "Woodkin Curse",
  "Woodland Refuge",
];

export const sword = [
  "Bayleon, Sovereign Light",
  "Mistolina, Forest Princess",
  "Tsubaki of the Demon Blade",
  "Leod, the Crescent Blade",
  "King's Might",
  "Troya, Thunder of Hagelberg",
  "Valse, Champion Deadeye",
  "Princess's Strike",
  "Swift Tigress",
  "Lupine Axeman",
  "Dauntless Commander",
  "Tempered Aether",
  "Elegance in Action",

  "Uzuki Shimamura [P.C.S.]",
  "Kyoko Igarashi [P.C.S.]",
  "Miho Kohinata [P.C.S.]",
  "Rin Shibuya [Triad Primus]",
  "Nao Kamiya [Over the Rainbow]",
  "Karen Hojo [Song for Life]",
  "Chizuru Matsuo",
  "Mio Honda [Positive Passion]",
  "Miria Akagi",
  "Miu Yaguchi",
  "Kumiko Matsuyama",
  "Kyoko Igarashi",
  "Nagi Hisakawa",
  "Rin Shibuya",
  "Mio Honda",
  "Uzuki Shimamura",
  "Anzu Futaba",
  "Karen Hojo",
  "Sparkling Days",
  "Nao Kamiya",
  "Mayu Sakuma",
  "Kirari Moroboshi",
  "Miho Kohinata",
  "Angelic Maid",

  "Kagemitsu, Matchless Blade",
  "Ralmia, Sonic Racer",
  "Steadfast Samurai",
  "Hero of Antiquity",
  "Courtly Dance",
  "Quickdraw Maven",
  "Twinsword Master",
  "Grand Acquisition",
  "Samurai Outlaw",
  "Adept Thief",
  "Petalwink Paladin",
  "Levin Scholar",
  "Breakneck Draw",

  "Octrice, Omen of Usurpation",
  "Magna Legacy",
  "Apostle of Usurpation",
  "Empyreal Swordsman",
  "Confront Adversity",
  "Disciple of Usurpation",
  "Fervent Maachine Soldier",
  "Geno, Machine Artisan",
  "Servant of Usurpation",
  "Captain Meteo",
  "Gravikinetic Warrior",
  "Usurping Spineblade",
  "Avaritia",
  "Mars, Silent Flame General",
  "Gawain of the Round Table",
  "Barbarossa",
  "Perseus",
  "Cyclone Blade",
  "Chivalrous Charge",
  "Shrouded Assassin",
  "Lord General Romeo",
  "Round Table Assembly",
  "Princess Juliet",
  "Flail Knight",
  "Pollux",
  "Tristan of the Round Table",
  "Armor of the Stars",
  "Cinderella",
  "Valiant Fencer",
  "Maisy, Red Riding Hood",
  "Amerro, Spear Knight",
  "Castle in the Sky",
  "Young Ogrehunter Momo",
  "Mach Knight",
  "Kiss of the Princess",
  "Rabbit Ear Attendant",
  "Old Man and Old Woman",
  "Bladed Hedgehog",
  "Ironwrought Defender",
  "Heroic Entry",
  "Tokai Teio",
  "Narita Brian",
  "Winning Ticket",
  "Outrunning the Encroaching Heat",
  "Air Groove",
  "Hishi Amazon",
  "Trial Initiation",
  "Sirius Symboli",
  "Narita Taishin",
  "Symboli Rudolf",
  "Biko Pegasus",
  "Fuji Kiseki",
  "Albert Levin Saber",
  "Alexander",
  "Amelia, Silver Paladin",
  "Leonidas",
  "White Paladin",
  "Jeno, Levin Vanguard",
  "Yurius, Levin Duke",
  "Whole-Souled Swing",
  "Swift Infiltrator",
  "Samurai",
  "Avant Blader",
  "Flame Soldier",
  "Gunner Maid Seria",
  "Tsubasa",
  "Latham, Vanguard Captain",
  "Floral Fencer",
  "Moonlight Assassin",
  "White General",
  "Fencer",
  "Oathless Knight",
  "Quickblader",
  "Unbridled Fury",
  "Sea Queen Otohime",
  "Aurelia, Regal Saber",
  "Shadowed Assassin",
  "Frontguard General",
  "Alwida's Command",
  "Royal Banner",
  "Maid Leader",
  "Gemstaff Commander",
  "Sage Commander",
  "Swordsman",
  "Pompous Princess",
  "Ninja Master",
  "Arthurian Light",
  "Ninja Trainee",
  "Fervid Soldier",
  "Luminous Knight",
  "Veteran Lancer",
  "Navy Lieutenant",
  "Novice Trooper",
  "Forge Weaponry",
  "Onslaught",
];

export const rune = [
  "Tetra, Sapphire Rebel",
  "Belphomet, Lord of Aiolon",
  "Riley, Hydroshaman",
  "Eleanor, Cosmic Flower",
  "Delta Cannon",
  "Displacer Bot",
  "Mechanized Lifeform",
  "Splendid Conjury",
  "Mechastaff Sorcerer",
  "Prototype Warrior",
  "Magiblade Witch",
  "Presto Chango",
  "Sagacious Core",

  "Chika Yokoyama",
  "Akiha Ikebukuro",
  "Yasuha Okazaki",
  "Seira Mizuki",
  "Mizuki Kawashima",
  "Shiki Ichinose",
  "Syuko Shiomi",
  "Kanade Hayami",
  "Center Street",
  "Hina Araki",
  "Frederica Miyamoto",
  "Precocious Little Devil",
  "Sarina Matsumoto",
  "Rika Jougasaki",
  "Sae Kobayakawa",
  "Tomoe Murakami",
  "Full Bloom Panorama",

  "Kuon, Founder of Onmyodo",
  "Mysteria, Magic Founder",
  "Curse Crafter",
  "Hulking Giant",
  "Shikigami Summons",
  "Demoncaller",
  "Traditional Sorcerer",
  "Crimson Meteor Storm",
  "Talisman Disciple",
  "Charming Gentlemouse",
  "Passionate Potioneer",
  "Golem's Rampage",
  "Mirror of Truth",

  "Raio, Omen of Truth",
  "Lishenna, Omen of Destruction",
  "Apostle of Truth",
  "Safira, Synthetic Beast",
  "Destructive Refrain",
  "Iron Staff Mechanic",
  "Truth's Adjudication",
  "Monochromatic Destruction",
  "Disciple of Truth",
  "Disciple of Destruction",
  "Servant of Destruction",
  "Honest Cohort",
  "Metaproduction",
  "Wordwielder Ginger",
  "Giant Chimera",
  "Star Reader Stella",
  "Europa",
  "Chain of Calling",
  "Noble Instruction",
  "Freshman Lou",
  "Magic Illusionist",
  "Concentration",
  "Show of Loyalty",
  "Dazzling Healer",
  "Mage of Nightfall",
  "Astrologist of the Mist",
  "Magic Owl",
  "Starseer's Telescope",
  "Wizardess of Oz",
  "Witch of Calamity, Millie Parfait",
  "Mystic King",
  "Falise, Leonardian Mage",
  "Milady, Mystic Queen",
  "Check",
  "Mr. Heinlein, Shadow Mage",
  "Magical Knight",
  "Gingerbread House",
  "It's a Sweets Buffet!",
  "Witch of Sweets",
  "Magical Rook",
  "Magical Bishop",
  "Blitz",
  "Witch's Cauldron",
  "The Cauldron of Calamity",
  "Agnes Tachyon",
  "Daiwa Scarlet",
  "Vodka",
  "Make! Some! NOISE!",
  "Zenno Rob Roy",
  "Agens Digital",
  "Lamplit Training of a Witch-to-Be",
  "Admire Vega",
  "Kawakami Princess",
  "Nakayama Festa",
  "Tosen Jordan",
  "Narita Top Road",
  "Daria Dimensional Witch",
  "Sun Oracle Pascale",
  "Anne, Belle oF Mysteria",
  "Grea the Dragonborn",
  "Rimewind",
  "Remi & Rami, Witchy Duo",
  "Shadow Witch",
  "Multipart Expirement",
  "Craig, Wizard of Mysteria",
  "Grand Gargoyle",
  "Witchbolt",
  "Magical Strategy",
  "Red-Hot Ritual",
  "Mythril Golem",
  "Rune Blade Summoner",
  "Demonflame Mage",
  "Insight",
  "Fire Chain",
  "Penguin Wizard",
  "Sammy Wizard's Apprentice",
  "Magic Missle",
  "Conjure Golem",
  "Arch Summoner Erasmus",
  "Merlin",
  "Ancient Alchemist",
  "Arcane Enlightenment",
  "Dimension Shift",
  "Juno's Secret Laboratory",
  "Spectral Wizard",
  "Flame Destroyer",
  "Dragonbond Mage",
  "Golem Protection",
  "Alchemical Lore",
  "Fate's Hand",
  "Price of Magic",
  "Runic Guardian",
  "Crafty Warlock",
  "Lightning Shooter",
  "Wind Blast",
  "Sorcery Cache",
  "Fiery Embrace",
  "Alchemist's Workshop",
  "Teachings of Creation",
];

export const dragon = [
  "Valdain, Cursed Shadow",
  "Neptune, Tidemistress",
  "Wildfire Tyrannosaur",
  "Marion, Elegant Dragonewt",
  "Shadow's Corrosion",
  "Bubbleborne Mermaid",
  "Hoarfrost Triceratops",
  "Whirlwind Pteranodon",
  "Dragonewt Needler",
  "Lightning Velociraptor",
  "Doting Dragoneer",
  "Boomfish",
  "Feral Aether",

  "Nene Kurihara",
  "Kaoru Ryuzaki",
  "Akari Tsujino",
  "Yui Ohtsuki",
  "Fumika Sagisawa",
  "Akira Sunazuka",
  "Tsukasa Kiryu",
  "Arisu Tachibana",
  "Yuka Nakano",
  "Unbound Emotion",
  "Tokiko Zaizen",
  "Noriko Shiina",
  "Yukari Mizumoto",
  "Noa Takamine",
  "Mode Estivale",

  "Garyu, Surpreme Dragonkin",
  "Filene, Whitefrost Dragonewt",
  "Phoenix Empress",
  "Wyrm God of the Skies",
  "Whitefrost Whisper",
  "Ice Dancing Dragonewt",
  "Jadelong Tactician",
  "Aquascale Stalwart",
  "Swordwhip Dragoon",
  "Dragonblader",
  "Trident Merman",
  "Dragon Chef",
  "Flamewinged Might",

  "Galmieux, Omen of Disdain",
  "Electromagical Rhino",
  "Apostle of Disdain",
  "God Bullet Golem",
  "Disdainful Rending",
  "Disciple of Disdain",
  "Cursed Stone",
  "Amethyst Giant",
  "Servant of Disdain",
  "Silver Automaton",
  "Airship Whale",
  "Colossal Construct",
  "Total Domination",
  "Sibyl of the Waterwyrm",
  "Kallen, Crimson Yaksha",
  "Python",
  "Prime Dragon Keeper",
  "Star Phoenix",
  "Lightning Blast",
  "Guren Revolt",
  "Venomous Pucewyrm",
  "Cetus",
  "Dragonewt Fist",
  "Divine Tiger",
  "Dragonrearer Matilda",
  "Aqua Nerid",
  "Hippocampus",
  "Scaled Berserker",
  "Dragon's Nest",
  "Jabberwock U",
  "Lævateinn Dragon",
  "Red Ragewyrm",
  "Draconir, Knuckle Dragon",
  "Tilting at Windmills",
  "Master of Draconic Arts",
  "Hammer Dragonewt",
  "Draconic Smash",
  "Elder Tortoise",
  "Trinity Dragon",
  "Dragon Summoner",
  "Lance Lizard",
  "Armor Burst",
  "Special Week",
  "Oguri Cap",
  "Seiun Sky",
  "Champion's Passion",
  "King Halo",
  "Tamamo Cross",
  "Flowers for You",
  "Bamboo Memory",
  "Yaeno Muteki",
  "Grass Wonder",
  "Super Creek",
  "Hishi Akebono",
  "Imperial Dragoon",
  "Dragonsong Flute",
  "Neptune",
  "Draconic Fervor",
  "Polyphonic Roar",
  "Siegfried",
  "Transmogrified Wyrm",
  "Dracomancer's Rites",
  "Wildfang Dragonewt",
  "Mushussu",
  "Dragontamer",
  "Twin-Headed Dragon",
  "Draconic Armor",
  "Fafnir",
  "Dragon Oracle",
  "Dragon Warrior",
  "Dragonewt Princess",
  "Dragonguard",
  "Roc",
  "Glint Dragon",
  "Dragonrider",
  "Seabrand Dragon",
  "Dark Dragoon Forte",
  "Zirnitra",
  "Aiela, Dragon Knight",
  "Genesis Dragon",
  "Shapeshifting Mage",
  "Phoenix Roost",
  "Wyvern Cavalier",
  "Dragonewt Scholar",
  "Shenlong",
  "Imprisoned Dragon",
  "Conflagration",
  "Serpent's Wrath",
  "Wyrm Spire",
  "Ivory Dragon",
  "Fire Lizard",
  "Ace Dragoon",
  "Mist Dragon",
  "Dread Dragon",
  "Blazing Breath",
  "Dragon Wings",
  "Dragon Emissary",
];

export const abyss = [
  "Mono, Garnet Rebel",
  "Kudlak",
  "Aenea, Amethyst Rebel",
  "Doublame, Duke and Dame",
  "Alpha Drive",
  "Nicola, Forbidden Strength",
  "Hellblaze Demon",
  "Forbidden Art",
  "Robozombie",
  "Bone Drone",
  "Berserk Demon",
  "Ghostwriter",
  "Sanguine Core",

  "Yukimi Sajo",
  "Ranko Kanzaki",
  "Sachiko Koshimizu",
  "Takumi Mukai",
  "Chitose Kurosaki",
  "Whispers of a Dream",
  "Chiyo Shirayuki",
  "Aki Yamato",
  "My Life, My Sounds",
  "Ryo Matsunaga",
  "Mirei Hayasaka",
  "Rina Fujimoto",
  "Syoko Hoshi",
  "Last Daylight",

  "Ginsetsu, Great Fox",
  "Aragavy the Berserker",
  "Shuten-Doji",
  "Bear Pelt Warrior",
  "Yuzuki, Righteous Demon",
  "Kasha",
  "Cougar Pelt Warrior",
  "Unleash the Nightmare",
  "Zashiki-Warashi",
  "Antelope Pelt Warrior",
  "Rookie Succubus",
  "Demonic Procession",
  "Berserker's Pelt",

  "Valnareik, Omen of Lust",
  "Rulenye, Omen of Silence",
  "Apostle of Lust",
  "Apostle of Silence",
  "Disciple of Lust",
  "Masked Puppet",
  "Wings of Lust",
  "Silent Purge",
  "Servant of Lust",
  "Servant of Silence",
  "Hamelin",
  "Embracing Wings",
  "Thundering Roar",
  "Venomfang Medusa",
  "Howling Demon",
  "Demonlord Eachtar",
  "Lelouch, Leader of the Black Knights",
  "Stheno",
  "Trial of the Gorgons",
  "Fenrir",
  "Euryale",
  "Grave Desecration",
  "Emperor's Command",
  "Demonic Drummer",
  "Castor",
  "Frogbat",
  "Scorpius",
  "Venomous Bite",
  "Dark Alice",
  "Masquerade Ghost",
  "Odile, Black Swan",
  "Demonium, Punk Devil",
  "Baccherus, Peppy Ghostie",
  "Demon Maestro",
  "Trombone Devil",
  "Furtive Fangs",
  "Pumpkin Necromancer",
  "Parade Raven",
  "Mischievous Zombie",
  "Devilish Flautist",
  "Infernal Orchestration",
  "Maruzensky",
  "Rice Shower",
  "Nice Nature",
  "7 More Centimeters",
  "Fine Motion",
  "Mayano Top Gun",
  "My Solo Drawn to Raindrop Drums",
  "Curren Chan",
  "Twin Turbo",
  "Sakura Chiyono O",
  "Seeking the Pearl",
  "Matikanetannhauser",
  "Vania, Vampire Princess",
  "Soul Dealer",
  "Underworld Watchman Khawy",
  "Azazel",
  "Vampiric Fortress",
  "Veight, Vampire Noble",
  "Trick Dullahan",
  "Precious Bloodfangs",
  "Mini Soul Devil",
  "Moriana the Bejeweled",
  "Demonic Hedonist",
  "Bone Chimera",
  "Necrocarnival",
  "Queen Vampire",
  "Alucard",
  "Playful Necomancer",
  "Midnight Vampire",
  "Night Horde",
  "Lesser Mummy",
  "Lilith",
  "Undying Resentment",
  "Summon Bloodkin",
  "Cerberus",
  "Lord Atomy",
  "Medusa",
  "Righteous Devil",
  "Mordecai the Duelist",
  "Dire Bond",
  "Hell's Unleasher",
  "Crazed Executioner",
  "Dark Summoner",
  "Dark General",
  "Phantom Howl",
  "Death's Breath",
  "Soul Conversion",
  "Skeleton Fighter",
  "Ambling Wraith",
  "Spectre",
  "Spartoi Sergeant",
  "Rabbit Necromancer",
  "Wardrobe Raider",
  "Undead King",
  "Razory Claw",
];

export const haven = [
  "Limonia, Flawed Saint",
  "Lapis, Glorious Seraph",
  "Father Refinement",
  "Marione, Light of Balance",
  "Augmentation Bestowal",
  "Bunny-Eared Administrator",
  "Robofalcon",
  "Marcotte, Heretical Sister",
  "Ironknuckle Nun",
  "Dark Bishop",
  "Meowskers, Ruff-Tuff Major",
  "Saintly Core",
  "Meowskers Ambush!",

  "Karin Domyoji",
  "Kako Takafuji",
  "Akane Hino [Positive Passion]",
  "Kaede Takagaki",
  "Shin Sato",
  "Nana Abe",
  "Akane Hino",
  "Classroom Lily",
  "Risa Matoba",
  "Haru Yuuki",
  "Psychic Maiden",
  "Natalia",
  "Shizuku Oikawa",
  "Layla",
  "Sanae Katagiri",
  "Winter Night Prayer",

  "Wilbert, Grand Knight",
  "Karula, Arts Master",
  "Saintly Leader",
  "Phantom Blade Wielder",
  "Manifest Devotion",
  "Holy Lancer",
  "Boost Kicker",
  "Feather Sanctuary",
  "Winged Staff Priestess",
  "Gravity Grappler",
  "Barrage Brawler",
  "Holy Counterattack",
  "Focus",

  "Marwynn, Omen of Repose",
  "Deus Ex Machina",
  "Apostle of Repose",
  "Hakrabi",
  "Ancient Protector",
  "Disciple of Repose",
  "Unidentified Subject",
  "Silver Cog Spinner",
  "Servant of Repose",
  "Demon's Epitaph",
  "The Saviors",
  "Realm of Repose",
  "Ancient Amplifier",
  "Aether of the White Wing",
  "Dark Jeanne",
  "Zoe, Princess of Goldenia",
  "Andromeda",
  "Globe of the Starways",
  "Dark Charisma",
  "Star Priestess",
  "Calydonian Boar",
  "Star Torrent",
  "Starchaser Sprite",
  "Sister of Punishment",
  "Mist Shaman",
  "Octobishop",
  "Candelabra of Prayers",
  "Princess Snow White",
  "Diamond Master",
  "Odette, White Swan",
  "Wingy Chirpy Gemstone",
  "Alice's Adventure",
  "White Knight",
  "Ruby Falcon",
  "March Hare's Teatime",
  "Tin Soldier",
  "Pinion Prince",
  "Birdkeeping Disciple",
  "Amethyst Lion",
  "Bejeweled Shrine",
  "Mejiro McQueen",
  "Gold Ship",
  "Ikuno Dictus",
  "The Will to Overtake",
  "Meisho Doto",
  "Mejiro Ryan",
  "Fate's Forecast",
  "Inari One",
  "Mejiro Dober",
  "Mejiro Ardan",
  "Mejiro Palmer",
  "T.M Opera O",
  "Heavenly Aegis",
  "Enstatued Seraph",
  "Kaguya",
  "Tribunal of Good and Evil",
  "Elana's Prayer",
  "Radiance Angel",
  "Saphire Priestess",
  "Beastcall Aria",
  "Frog Cleric",
  "Sky Sprite",
  "Soul Collector",
  "Sledgehammer Exorcist",
  "Emerald Maiden",
  "Skullfane",
  "Hare of Illusions",
  "Priest of the Cudgel",
  "Acolyte's Light",
  "Dual Flames",
  "Ardent Nun",
  "Guardian Nun",
  "Pinion Prayer",
  "Beastly Vow",
  "Moon Al-mi'raj",
  "Jeanne d'Arc",
  "Arch Priestess Laelia",
  "Themis's Decree",
  "Chorus of Prayer",
  "Sacred Plea",
  "Temple Defender",
  "Prism Priestess",
  "Cleric Lancer",
  "Shrine Knight Maiden",
  "Blackened Scripture",
  "Dark Offering",
  "Holy Sentinel",
  "Cruel Priestess",
  "Sister Initiate",
  "Mainyu",
  "Snake Priestess",
  "Curate",
  "Hallowed Dogma",
  "Guardian Sun",
  "Death Sentence",
];

export const neutral = [
  "Technolord",
  "Viridia Magna",
  "Mechawing Angel",
  "Desert Pathfinder",
  "Maisha, Hero of Purgation",
  "Robogoblin",
  "Colorful Cook",
  "Purgation's Blade",
  "Aldis, Trendsetting Seraph",
  "Mechagun Wielder",
  "Extreme Carrot",

  "New Generations",
  "New Wave",
  "Master Trainer",
  "Expert Trainer",
  "Trainer",
  "Rookie Trainer",

  "Mammoth God's Colosseum",
  "Badb Catha",
  "Mithra, Daybreak Diety",
  "Fall from Grace",
  "Colosseum on High",
  "Chaht, Ringside Announcer",
  "Clash of Heroes",
  "Biofrabrication",
  "Sweet-Tooth Sleuth",
  "Bazooka Goblins",
  "Sentry Gate",

  "Mjerrabaine, Omen of One",
  "Gilnelise, Omen of Craving",
  "Apostle of Craving",
  "Lyrial, Archer Throne",
  "Feena, Dynamite Daredevil",
  "Rosa, Mech Wing Maiden",
  "Enlightenment",
  "Craving's Splendor",
  "Cat Cannoneer",
  "Steel Demolitionist",
  "Gliesaray",
  "Zodiac Demon",
  "Israfil",
  "Grimnir, War Cyclone",
  "Arriet, Soothing Harpist",
  "Staircase to Paradise",
  "Purehearted Singer",
  "Goblin Princess",
  "Mystic Ring",
  "Owlcat",
  "Mr. Full Moon",
  "Night's Way",
  "Alice, Wonderland Explorer",
  "Angel of Chaos",
  "Rapunzel",
  "Seraph of Sin",
  "Garuel, Seraphic Leo",
  "Actress Feria",
  "Humpty Dumpty",
  "Winged Inversion",
  "Angel of Darkness",
  "Harbringer of the Night",
  "Eggsplosion",
  "Tazuna Hayakawa [Traccen Reception]",
  "Riko Kashimoto",
  "Aoi Kiryuin",
  "Riko Kashimoto [Planned Perfection]",
  "Close Knit Ambitions",
  "Take a Jab!",
  "Aoi Kiryuin [Trainers' Teamwork]",
  "Sasami Anshinzawa",
  "Tazuna Hayakawa",
  "Dark Angel Olivia",
  "Bahamut",
  "Demonic Simulacrum",
  "Archangel Reina",
  "Surefire Bullet",
  "Unicorn Dancer Unicorn",
  "Gourmet Emperor Khaiza",
  "Call of Cocytus",
  "Hamsa",
  "Sektor",
  "Dance of Death",
  "Gabriel",
  "Lucifer",
  "Flame and Glass",
  "Urd",
  "Wind God",
  "Gilgamesh",
  "Bellringer Angel",
  "Altered Fate",
  "Path to Purgatory",
  "Lizardman",
  "Goblinmount Demon",
  "Harnessed Flame",
  "Harnessed Glass",
  "Demonic Strike",
  "Execution",
  "Trail of Light",
  "Goblin",
  "Fighter",
  "Goliath",
  "Angelic Sword Maiden",
  "Healing Angel",
  "Angelic Snipe",
  "Angelic Barrage",
];
